import React, { Component } from "react"
import {
  Button,
  FormText,
  FormFeedback,
  FormGroup,
  Label,
  Input,
} from "reactstrap"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = { name: "", email: "", message: "" }
  }

  /* Here’s the juicy bit for posting the form submission */

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))

    e.preventDefault()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    const { name, email, message } = this.state

    var is_valid_email = re.test(email)

    var chars = message.length
    var is_valid_message = chars > 50
    var charsSuccess = "Ok!"

    var formFeedback = "Please write at least 50 characters."

    if (is_valid_message) {
      formFeedback = ""
    }

    return (
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            valid={name !== ""}
            placeholder="Name"
            type="text"
            name="name"
            value={name}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            valid={is_valid_email}
            placeholder="Email"
            type="email"
            name="email"
            value={email}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            valid={is_valid_message}
            placeholder="Message"
            type="textarea"
            name="message"
            value={message}
            onChange={this.handleChange}
          />
          <FormFeedback valid={is_valid_message}>{charsSuccess}</FormFeedback>
          <FormText> {formFeedback} </FormText>
        </FormGroup>
        <Button color="primary" type="submit">
          Send
        </Button>
      </form>
    )
  }
}

export default ContactForm
