import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Media } from "reactstrap"
import ContactForm from "../components/contactForm"

var styles = {
  img: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
  },
  player: {
    songTitle: {
      display: "inline",
    },
  },
}

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <h1 className="whiteText">Contact</h1>
    <ContactForm />
  </Layout>
)

export default Contact
